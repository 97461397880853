import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import FeedbackCard from "../components/feedback_card"
import AboutUs from "../components/about_us"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        contentType='article'
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>
            {post.frontmatter.date} by {post.frontmatter.author}
          </p>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <section className="text-charcoal p-3">
          Tags:
          {post.frontmatter.tags.split(", ").map(tag => (
            <span
              key={[post.fields.slug, "-", tag].join("")}
              className="text-white bg-ptGreen text-xs rounded-full py-1 px-2 m-1 capitalize"
            >
              {tag}
            </span>
          ))}
        </section>
      </article>
      <section className="about m-2 mb-10">
        <div
          id="form"
          className="lg:mr-20 grid grid-cols-1 lg:grid-cols-2 gap-4"
        >
          <div className="m-auto order-last mt-6 lg:order-first lg:mr-10 pb-4">
            <FeedbackCard location={post.fields.slug} />
          </div>
          <div className="mt-4 ml-1 mr-10">
            <AboutUs />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        project
        author
        tags
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
